import { WeekDay } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  LocalNotifications,
  LocalNotificationSchema,
  Weekday,
} from "@capacitor/local-notifications";
import { AlertController } from "@ionic/angular";
import { EventType } from "../models/calendar-event.model";
import { Reminder, ReminderOffset } from "../models/reminder.model";
import { ApiService } from "./api.service";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import {
  differenceInDays,
  isBefore,
  parse,
  startOfYear,
  addYears,
} from "date-fns";
import { environment } from "src/environments/environment";

const title = "Waste Info - Collection Reminder";
const body = "Don't forget to put your $ out, tap here for more details.";
const maxNotifications = 64; // iOS Max Notifications
const iconColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--ion-color-primary")
  .trim();
const onClickUrl =
  environment.layout === "tabs" ? "/tabs/calendar" : "/calendar";

@Injectable({
  providedIn: "root",
})
export class RemindersService {
  constructor(
    private api: ApiService,
    private alertCtrl: AlertController,
    private router: Router
  ) {
    LocalNotifications.addListener("localNotificationReceived", async () => {
      const alert = await this.alertCtrl.create({
        header: "Collection Reminder",
        message:
          "Your bins are scheduled to be collected tomorrow, " +
          "please see the calendar for more details.",
        buttons: [
          {
            text: "OK",
            role: "confirm",
            handler: async () => {
              return await this.router.navigate([onClickUrl]);
            },
          },
        ],
      });
      await alert.present();
    });
    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      async () => {
        return await this.router.navigate([onClickUrl]);
      }
    );
  }

  public async checkReminders(): Promise<void> {
    let reminder = await this.api.getReminder();
    if (reminder) {
      if (!this.isReminder(reminder)) {
        reminder = {
          offset: ReminderOffset.dayBefore,
          hours: +reminder.split(":")[0],
          minutes: +reminder.split(":")[1],
          recurring: true,
          eventTypes: [
            EventType.waste,
            EventType.recycle,
            EventType.organic,
            EventType.cleanUp,
          ],
        };
      }
      await this.setReminders(reminder);
    }
  }

  public async notificationsAllowed(): Promise<boolean> {
    try {
      let permissionStatus = await LocalNotifications.checkPermissions();

      if (
        permissionStatus.display === "prompt" ||
        permissionStatus.display === "prompt-with-rationale"
      ) {
        permissionStatus = await LocalNotifications.requestPermissions();
      }

      if (permissionStatus.display !== "granted") {
        const alert = await this.alertCtrl.create({
          header: "Push notifications not enabled",
          message:
            "To set bin night reminders you must first turn on push notifications. Click below to change the permission in settings.",
          buttons: [
            {
              text: "Turn on",
              role: "open",
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ],
        });

        await alert.present();
        const { role } = await alert.onDidDismiss();
        if (role === "open") {
          await NativeSettings.open({
            optionAndroid: AndroidSettings.AppNotification,
            optionIOS: IOSSettings.App,
          });
        }
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async setReminders(reminder: Reminder): Promise<Reminder> {
    this.destroyAllReminders();
    const now = new Date();
    return new Promise<Reminder>((resolve) => {
      this.api.getEvents(now, addYears(new Date(), 1)).subscribe({
        next: async (events) => {
          const notifications: LocalNotificationSchema[] = [];
          const weekdays = new Set();
          events = events.filter((event) =>
            reminder.eventTypes.includes(event.event_type)
          );
          for (let i = 0; notifications.length < maxNotifications; i++) {
            if (events[i]) {
              if (events[i].hasOwnProperty("dow")) {
                const startOfWeek = new Date(
                  new Date().setDate(now.getDate() - now.getDay())
                );
                const date: Date = this.getOffsetDate(
                  new Date(
                    startOfWeek.setDate(
                      startOfWeek.getDate() + events[i].dow[0]
                    )
                  ),
                  reminder
                );
                const dayOfYear = Math.floor(
                  (date.getTime() -
                    new Date(date.getFullYear(), 0, 0).getTime()) /
                    1000 /
                    60 /
                    60 /
                    24
                );
                let notification: LocalNotificationSchema = notifications.find(
                  (n: LocalNotificationSchema) => n.id === dayOfYear
                );
                if (notification) {
                  notification.body = body.replace(
                    "$",
                    events[i].event_type === EventType.cleanUp
                      ? "bulk household items"
                      : "bins"
                  );
                } else {
                  notification = {
                    id: dayOfYear,
                    title,
                    body: body.replace(
                      "$",
                      events[i].event_type === EventType.cleanUp
                        ? "bulk household items"
                        : "bins"
                    ),
                    schedule: {
                      on: {
                        weekday: Weekday[WeekDay[date.getDay()]],
                        hour: date.getHours(),
                        minute: date.getMinutes(),
                        second: date.getSeconds(),
                      },
                      allowWhileIdle: true,
                    },
                    smallIcon: "res://ic_stat_onesignal_default",
                    iconColor,
                  };
                  notifications.push(notification);
                  weekdays.add(date.getDay());
                }
              } else {
                const reminderStartDate: Date = this.getOffsetDate(
                  new Date(events[i].start),
                  reminder
                );

                if (isBefore(reminderStartDate, now)) {
                  continue;
                }

                const dayOfYear = differenceInDays(
                  reminderStartDate,
                  startOfYear(reminderStartDate)
                );

                if (weekdays.has(reminderStartDate.getDay())) {
                  continue;
                }

                let notification: LocalNotificationSchema = notifications.find(
                  (n: LocalNotificationSchema) => n.id === dayOfYear
                );
                if (notification) {
                  notification.body = body.replace(
                    "$",
                    events[i].event_type === EventType.cleanUp
                      ? "bulk household items"
                      : "bins"
                  );
                } else {
                  notification = {
                    id: dayOfYear,
                    title,
                    body: body.replace(
                      "$",
                      events[i].event_type === EventType.cleanUp
                        ? "bulk household items"
                        : "bins"
                    ),
                    schedule: {
                      at: reminderStartDate,
                      allowWhileIdle: true,
                    },
                    smallIcon: "res://ic_stat_onesignal_default",
                    iconColor,
                  };
                  notifications.push(notification);
                }
              }
            } else {
              break;
            }
          }
          await LocalNotifications.schedule({ notifications });
          console.log(
            "Reminder(s) scheduled for:\n- " +
              notifications
                .map(
                  (n) =>
                    n.schedule.at ||
                    `Every ${Weekday[n.schedule.on.weekday]} at ${
                      n.schedule.on.hour
                    }:${n.schedule.on.minute.toString().padStart(2, "0")}:00`
                )
                .join("\n- ")
          );
          reminder.collectionsDays = [
            ...new Set(
              notifications.map((n) =>
                n.schedule.at
                  ? n.schedule.at.getDay() + 1
                  : n.schedule.on.weekday
              )
            ),
          ].sort();
          this.api.setReminder(reminder);
          resolve(reminder);
        },
        error: (error) => {
          console.error("ApiService.getEvents Error: " + error);
          resolve(null);
        },
      });
    });
  }

  public async destroyAllReminders(): Promise<void> {
    const pending = await LocalNotifications.getPending();
    if (pending.notifications.length > 0) {
      await LocalNotifications.cancel({ notifications: pending.notifications });
    }
    this.api.setReminder(null);
  }

  public isReminder(reminder: any): reminder is Reminder {
    if ((reminder as Reminder).offset) {
      return true;
    }
    return false;
  }

  private getOffsetDate(date: Date, reminder: Reminder): Date {
    date.setHours(reminder.hours, reminder.minutes, 0);
    date.setDate(date.getDate() - reminder.offset);
    return date;
  }
}
