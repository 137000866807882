import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Browser } from "@capacitor/browser";
import { SplashScreen } from "@capacitor/splash-screen";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import OneSignal from "onesignal-cordova-plugin";
import { environment } from "src/environments/environment";
import { AddressPage } from "./pages/address/address.page";
import { EulaPage } from "./pages/eula/eula.page";
import { ApiService } from "./services/api.service";
import { RemindersService } from "./services/reminders.service";
import { EnvironmentPage } from "./models/environment-page.model";
import { lastValueFrom } from "rxjs";
import { IframeService } from "./services/iframe.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public menuPages: EnvironmentPage[] = [...environment.pages];

  constructor(
    private router: Router,
    private api: ApiService,
    private platform: Platform,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private reminders: RemindersService,
    public iframeService: IframeService
  ) {}

  @HostListener("click", ["$event"]) public async onClick(
    event: Event
  ): Promise<void> {
    const element = event.target as HTMLElement;
    const anchor = element.closest("a");
    if (anchor) {
      const href = anchor.getAttribute("href") || "";
      if (href.includes("/#/")) {
        event.preventDefault();
        this.router.navigate([href.replace("/#", "")]);
      } else if (href && this.platform.is("pwa")) {
        event.preventDefault();
        await Browser.open({ url: href });
      }
    }
  }

  public async ngOnInit(): Promise<void> {
    await this.platform.ready();
    await this.api.ready();
    this.updateSelected();
    this.menuPages.unshift({
      title: "Change Address and Alerts",
      icon: "settings",
      colour: "secondary",
      route: `/settings`,
    });
    this.menuPages.unshift({
      title: "Main Menu",
      icon: "menu",
      colour: "secondary",
      route: "/menu",
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateSelected();
      }
    });
    SplashScreen.hide();

    const serverConnection = await this.api.testConnection();

    if (!serverConnection) {
      return;
    }

    this.api.getFormSettings();
    this.checkVersion();

    if (this.platform.is("capacitor")) {
      this.oneSignalInit();
      this.reminders.checkReminders();
      const eulaAccepted = await this.api.getEulaAccepted();
      if (!eulaAccepted) {
        const modal = await this.modalCtrl.create({
          component: EulaPage,
          backdropDismiss: false,
        });
        modal.present();
      }
    } else {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  public updateSelected(): void {
    const path = window.location.pathname;
    if (path !== undefined) {
      this.selectedIndex = this.menuPages.findIndex(
        (page) => page.route === path
      );
    }
  }

  private oneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);
    OneSignal.setAppId(environment.oneSignalAppID);
    OneSignal.setLocationShared(false);
    OneSignal.setNotificationOpenedHandler((event) => {
      this.router.navigate([`/alerts/${event.notification.notificationId}`]);
    });
    OneSignal.getDeviceState((state) => {
      this.api.setPushToken(state.userId);
      FirebaseAnalytics.setUserId({ userId: state.userId });
    });
    OneSignal.addSubscriptionObserver((state) => {
      this.api.setPushToken(state.to.userId);
      FirebaseAnalytics.setUserId({ userId: state.to.userId });
    });
    OneSignal.promptForPushNotificationsWithUserResponse();
  }

  private async checkVersion(): Promise<void> {
    try {
      const res = await lastValueFrom(this.api.getVersion());
      const newVersionExists =
        res.current_version.localeCompare(environment.appVersion, undefined, {
          numeric: true,
        }) === 1;

      if (newVersionExists) {
        await this.showVersionAlert(res);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async showVersionAlert(res: any): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: "New Version Available",
      message: "There is a newer version of this app available for download.",
      buttons: [
        {
          text: "Later",
          role: "cancel",
        },
        {
          text: "OK",
          handler: async () => {
            await Browser.open({
              url: this.platform.is("ios")
                ? res.itunes_url
                : res.google_play_url,
            });
          },
        },
      ],
    });
    await alert.present();
  }
}
