import { Location } from "./location.model";
import { Category } from "./category.model";

export class Information {
  public static readonly DETAILS = "details";
  public static readonly MAP = "map";
  public static readonly LIST = "list";
  public static readonly MULTIPLE_LOCATION_MAP = "multiple_location_map";
  public static readonly WASTE = "waste";
  public static readonly RECYCLE = "recycle";
  public static readonly ORGANIC = "organic";
  public static readonly PAPER = "paper";
  public static readonly FOOD = "food";
  public static readonly CLEAN_UP = "clean_up";
  public static readonly HARD_WASTE = "hard_waste";
  public static readonly GREENWASTE = "greenwaste";
  public static readonly EXTERNAL_LINK = "external_link";
  public id: number | string;
  public title: string;
  public content: string;
  public latitude: number;
  public longitude: number;
  public children: Array<any>;
  public pageType: string;
  public symbol: string;
  public icon: string;
  public colour: string;
  public locations: Location[] = [];
  public categories: Category[];
  public url: string;
  public summary: string;
  public readonly icons = [
    "waste",
    "recycle",
    "organic",
    "paper",
    "food",
    "clean_up",
    "hard_waste",
    "greenwaste",
  ];
  public animate: string;

  constructor(
    id: number | string,
    title: string,
    pageType: string,
    content?: string,
    latitude?: number,
    longitude?: number,
    children?: Array<any>,
    locations?: Array<any>,
    categories?: Category[],
    colour?: string,
    icon?: string,
    url?: string,
    summary?: string
  ) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.pageType = pageType;
    this.latitude = latitude;
    this.longitude = longitude;
    this.children = children;
    this.categories = categories;
    this.colour = colour;
    this.icon = icon;
    this.url = url;
    this.summary = summary;
    this.setLocations(locations);
    this.setSymbol();
  }

  private setLocations(locations): void {
    if (locations) {
      for (const location of locations) {
        this.locations.push(
          new Location(
            location.id,
            location.name,
            location.details,
            location.latitude,
            location.longitude,
            location.colour,
            location.content,
            location.category_ids
          )
        );
      }
    }
  }

  private setSymbol(): void {
    switch (this.pageType) {
      case Information.WASTE:
        this.symbol = "bin";
        break;
      case Information.RECYCLE:
        this.symbol = "bin";
        break;
      case Information.ORGANIC:
        this.symbol = "bin";
        break;
      case Information.PAPER:
        this.symbol = "bin";
        break;
      case Information.FOOD:
        this.symbol = "bin";
        break;
      case Information.CLEAN_UP:
        this.symbol = "clean-up";
        break;
      case Information.HARD_WASTE:
        this.symbol = "ellipse";
        break;
      case Information.GREENWASTE:
        this.symbol = "ellipse";
        break;
    }
  }
}
