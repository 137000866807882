export const common = {
  council: "moira",
  layout: "grid",
  apiToken: "0bff59463c1c1afe01097ef437464de3",
  oneSignalAppID: "296be342-90a3-4645-a223-68cc753a3c69",
  googleSenderID: "644343698685",
  googleApiKey: "AIzaSyDKN7LwQoVL4KebIwmWA_RZMQ8OJtjifCw",
  appVersion: "2.1.2.0",
  googleDirectionsUrl: "https://maps.googleapis.com/maps/api/geocode/json",
  vouchersInfoId: 45,
  defaultPageSize: 50,
  mapMarkerPath:
    "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
  firebaseConfig: {
    apiKey: "AIzaSyAfgJMbf7Pf7l0c_yUDlk6dS8e6iSRCv7Q",
    authDomain: "moira-waste-info.firebaseapp.com",
    databaseURL: "https://moira-waste-info.firebaseio.com",
    projectId: "moira-waste-info",
    storageBucket: "moira-waste-info.appspot.com",
    messagingSenderId: "644343698685",
    appId: "1:644343698685:web:fa0ecfa431497730f01a94",
    measurementId: "G-SPCH6TRG9J"

  },
  pages: [
    {
      title: "Calendar",
      icon: "calendar",
      colour: "secondary",
      route: "/calendar",
    },
    {
      title: "News & Alerts",
      icon: "news",
      colour: "secondary",
      route: "/news-and-alerts",
    },
    {
      title: "A-Z of Waste",
      icon: "a-z",
      colour: "secondary",
      route: "/a-z",
    },
    {
      title: "Bin Info",
      icon: "bin",
      colour: "secondary",
      route: "/bin-info",
    },
    {
      title: "Events",
      icon: "event-calendar",
      colour: "secondary",
      route: "/info/22",
      params: { title: "Events" },
    },
    {
      title: "Facilities",
      icon: "lamp-lounge",
      colour: "secondary",
      route: "/info/4",
    },
    {
      title: "Submit a Waste Request",
      icon: "forms",
      colour: "secondary",
      route: "/forms",
    },
    {
      title: "Recycling Near Me",
      icon: "recycling-near-me",
      colour: "secondary",
      route: "/info-multi-map/21",
    },
    {
      title: "FAQs",
      icon: "feedback",
      colour: "secondary",
      route: "/info-details/1",
    },
    // {
    //   title: "Vouchers",
    //   icon: "voucher",
    //   colour: "secondary",
    //   route: "/vouchers",
    // },
    {
      title: "More Info",
      icon: "information-circle",
      colour: "secondary",
      route: "/more-info",
    },
  ],
};
