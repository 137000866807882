import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import {
  from,
  lastValueFrom,
  Observable,
  of,
  switchMap,
  throwError,
} from "rxjs";
import { isSameDay, startOfMonth } from "date-fns";
import { catchError, map, retry, tap, timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Alert } from "../models/alert.model";
import { CalendarEvent } from "../models/calendar-event.model";
import { Config } from "../models/config.model";
import { FormSetting } from "../models/form.model";
import { Information } from "../models/information.model";
import { Locality } from "../models/locality.model";
import { Location } from "../models/location.model";
import { Material } from "../models/material.model";
import { News, NewsType } from "../models/news.model";
import { ListProperty, Property } from "../models/property.model";
import { Reminder } from "../models/reminder.model";
import { Street } from "../models/street.model";
import { ErrorService } from "./error.service";
import { Address } from "../models/address.model";
import { IframeService } from "./iframe.service";

const apiProtocol = environment.apiProtocol;
const baseURL = `.${environment.apiDomain}/api/v1`;
const council = environment.council;
const properties = "/properties";
const streets = "/streets";
const localities = "/localities";
const materials = "/materials";
const information = "/info_pages";
const version = "/version";
const forms = "/forms";
const formSettings = "/form_settings";
const locations = "/locations";
const events = "/events";
const pushNotifications = "/push_notifications";
const propertySearch = "/property_search";
const news = "/news";
const dotJSON = ".json";
const headers = new HttpHeaders({
  "Content-Type": "application/json;",
  Authorization: `Token token="${environment.apiToken}"`,
});

const errorMsg = "Please check your network connection and try again.";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public formSettings: FormSetting[] = [];
  private propertyId: string;
  private address: string;
  private pushToken: string;
  private oldPushToken = "";
  private eulaAccepted: boolean;
  private reminder: string | Reminder;
  private verification: string;
  private recipient: Address;
  private name: string;
  private contactNo: string;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private toastCtrl: ToastController,
    private errorService: ErrorService,
    private iframeService: IframeService
  ) {
    this.storage.defineDriver(CordovaSQLiteDriver).then(() => this.ready());
  }

  public async ready(): Promise<void> {
    // Attempting to create storage in an iframe will result in error
    if (this.iframeService.inIframe()) {
      return;
    }

    await this.storage.create();
    [
      this.propertyId,
      this.address,
      this.pushToken,
      this.eulaAccepted,
      this.reminder,
      this.verification,
      this.recipient,
      this.name,
      this.contactNo,
    ] = await Promise.all([
      this.getPropertyId(),
      this.getAddress(),
      this.getPushToken(),
      this.getEulaAccepted(),
      this.getReminder(),
      this.getVerification(),
      this.getRecipient(),
      this.getName(),
      this.getContacNo(),
    ]);
  }

  public async testConnection(): Promise<boolean> {
    return lastValueFrom(
      this.http
        .get<Config>(
          `${apiProtocol}${council}${baseURL}${localities}${dotJSON}`,
          { headers, observe: "response" }
        )
        .pipe(
          catchError(() => of(false)),
          timeout(5000),
          map((response) => !!response)
        )
    );
  }

  public getAddresses(search: string): Observable<ListProperty[]> {
    const params = new HttpParams().set("query[address_i_cont]", search);
    const options = {
      headers,
      observe: "response" as "response",
      params,
    };
    const url = `${apiProtocol}${council}${baseURL}${propertySearch}${dotJSON}`;
    return this.http.get(url, options).pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError(this.handleError.bind(this))
    );
  }

  public async getPropertyId(): Promise<string> {
    if (this.propertyId) {
      return Promise.resolve(this.propertyId);
    } else {
      const propertyId = await this.storage.get("propertyId");
      this.propertyId = propertyId;
      return propertyId;
    }
  }

  public async setPropertyId(propertyId: string): Promise<void> {
    this.propertyId = propertyId;

    if (this.iframeService.inIframe()) {
      return;
    }

    this.storage.set("propertyId", propertyId);
    const pushToken = await this.getPushToken();
    this.sendPushToken(pushToken)
      .pipe(retry(5))
      .subscribe({
        next: (property) => {
          console.log(
            `Push token '${pushToken}' associated with property ID '${property.id}'`
          );
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  public async getAddress(): Promise<string> {
    if (this.address) {
      return Promise.resolve(this.address);
    } else if (!this.iframeService.inIframe()) {
      const address = await this.storage.get("address");
      this.address = address;
      return address;
    }
    return null;
  }

  public async setAddress(address: string): Promise<void> {
    this.address = address;

    if (this.iframeService.inIframe()) {
      return;
    }

    return this.storage.set("address", address);
  }

  public async getPushToken(): Promise<string> {
    if (this.pushToken) {
      return Promise.resolve(this.pushToken);
    } else {
      const pushToken = await this.storage.get("pushToken");
      this.pushToken = pushToken;
      return pushToken;
    }
  }

  public async setPushToken(pushToken: string): Promise<void> {
    this.oldPushToken = await this.getPushToken();
    this.pushToken = pushToken;
    await this.storage.set("pushToken", pushToken);
    const propertyId = await this.getPropertyId();
    if (propertyId) {
      this.sendPushToken(pushToken)
        .pipe(retry(5))
        .subscribe({
          next: () => {
            console.log(
              `Updated token for '${this.address}': '${this.oldPushToken}' => '${pushToken}'`
            );
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
  }

  public async getReminder(): Promise<string | Reminder> {
    if (this.reminder) {
      return Promise.resolve(this.reminder);
    } else {
      const reminder = await this.storage.get("reminder");
      this.reminder = reminder;
      return reminder;
    }
  }

  public async setReminder(reminders: Reminder): Promise<void> {
    this.reminder = reminders;
    return this.storage.set("reminder", reminders);
  }

  public async getEulaAccepted(): Promise<boolean> {
    if (this.eulaAccepted) {
      return Promise.resolve(this.eulaAccepted);
    } else {
      const eulaAccepted = await this.storage.get("eulaAccepted");
      this.eulaAccepted = eulaAccepted;
      return eulaAccepted;
    }
  }

  public async setEulaAccepted(eulaAccepted: boolean): Promise<void> {
    this.eulaAccepted = eulaAccepted;
    return this.storage.set("eulaAccepted", eulaAccepted);
  }

  public getEvents(start?: Date, end?: Date): Observable<CalendarEvent[]> {
    const start_string = start.toISOString();
    const end_string = end.toISOString();

    let params = new HttpParams();
    params = params.set("start", start_string);
    params = params.set("end", end_string);

    const options = {
      headers,
      params,
    };

    const connection$ = from(this.testConnection());

    // spaghetti that is necessary to be able to return an observable
    // if we can't connect to the server and the user wants to see the
    // current month, return cached events
    return connection$.pipe(
      switchMap((connection) => {
        if (!connection) {
          return from(this.retrieveStoredEvents(start_string, end_string));
        } else {
          return this.http
            .get<CalendarEvent[]>(
              `${apiProtocol}${council}${baseURL}${properties}/${this.propertyId}${dotJSON}`,
              options
            )
            .pipe(
              catchError(this.handleError.bind(this)),
              tap((events) => {
                if (
                  isSameDay(startOfMonth(new Date()), start) &&
                  !this.iframeService.inIframe()
                ) {
                  this.storage.set("events", {
                    start: start_string,
                    end: end_string,
                    events,
                  });
                }
              })
            );
        }
      })
    );
  }

  private async retrieveStoredEvents(
    start: string,
    end: string
  ): Promise<CalendarEvent[] | null> {
    const stored_data = await this.storage.get("events");

    if (stored_data && stored_data.start === start && stored_data.end === end) {
      return stored_data.events;
    }

    this.errorService.showToast(
      "Please check your network connection and try again."
    );
    return null;
  }

  public getLocalities() {
    const options = { headers };
    return this.http
      .get<Locality[]>(
        `${apiProtocol}${council}${baseURL}${localities}${dotJSON}`,
        options
      )
      .pipe(
        map((res: any) => res.localities),
        catchError(this.handleError.bind(this))
      );
  }

  public getStreets(localityId: number) {
    const params = new HttpParams().set("locality", localityId.toString());
    const options = { headers, params };
    return this.http
      .get<Street[]>(
        `${apiProtocol}${council}${baseURL}${streets}${dotJSON}`,
        options
      )
      .pipe(
        map((res: any) => res.streets),
        catchError(this.handleError.bind(this))
      );
  }

  public getProperties(streetId: number) {
    const params = new HttpParams().set("street", streetId.toString());
    const options = { headers, params };
    return this.http
      .get<Property[]>(
        `${apiProtocol}${council}${baseURL}${properties}${dotJSON}`,
        options
      )
      .pipe(
        map((res: any) => res.properties),
        catchError(this.handleError.bind(this))
      );
  }

  public getInfoPages(binInfo: boolean) {
    let params: HttpParams;
    if (binInfo) {
      params = new HttpParams().set("bin_info", "true");
    }
    const options = { headers, params };
    return this.http
      .get<Information[]>(
        `${apiProtocol}${council}${baseURL}${information}${dotJSON}`,
        options
      )
      .pipe(
        map((res: any) =>
          res.map(
            (page) =>
              new Information(
                page.id,
                page.title,
                page.pageType,
                page.content,
                page.latitude,
                page.longitude,
                page.children,
                page.locations,
                page.categories,
                page.colour,
                page.icon,
                page.url,
                page.summary
              )
          )
        ),
        catchError(this.handleError.bind(this))
      );
  }

  public getInfoPage(id: number) {
    const options = { headers };
    return this.http
      .get<Information | Information[]>(
        `${apiProtocol}${council}${baseURL}${information}/${id}${dotJSON}`,
        options
      )
      .pipe(
        map((res: any) => {
          if (Array.isArray(res)) {
            return res.map(
              (page) =>
                new Information(
                  page.id,
                  page.title,
                  page.pageType,
                  page.content,
                  page.latitude,
                  page.longitude,
                  page.children,
                  page.locations,
                  page.categories,
                  page.colour,
                  page.icon,
                  page.url,
                  page.summary
                )
            );
          } else {
            return new Information(
              res.id,
              res.title,
              res.pageType,
              res.content,
              res.latitude,
              res.longitude,
              res.children,
              res.locations,
              res.categories,
              res.colour,
              res.icon,
              res.url,
              res.summary
            );
          }
        }),
        catchError(this.handleError.bind(this))
      );
  }

  public getMaterials() {
    const options = { headers };
    return this.http
      .get<Material[]>(
        `${apiProtocol}${council}${baseURL}${materials}${dotJSON}`,
        options
      )
      .pipe(
        map((res: any) =>
          res.materials.map(
            (material) =>
              new Material(
                material.id,
                material.title,
                material.image,
                material.description,
                material.bin_type,
                material.category_id,
                material.keywords
              )
          )
        ),
        catchError(this.handleError.bind(this))
      );
  }

  public getMaterial(id: number) {
    const options = { headers };
    return this.http
      .get<Material>(
        `${apiProtocol}${council}${baseURL}${materials}/${id}${dotJSON}`,
        options
      )
      .pipe(
        map(
          (material: any) =>
            new Material(
              material.id,
              material.title,
              material.image,
              material.description,
              material.bin_type,
              material.category_id,
              material.keywords
            )
        ),
        catchError(this.handleError.bind(this))
      );
  }

  public getVersion() {
    const options = { headers };
    return this.http
      .get<Config>(
        `${apiProtocol}${council}${baseURL}${version}${dotJSON}`,
        options
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  public sendForm(body: string) {
    const options = { headers };
    return this.http
      .post(
        `${apiProtocol}${council}${baseURL}${forms}${dotJSON}`,
        body,
        options
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getFormSettings() {
    const options = { headers };
    return this.http
      .get<FormSetting[]>(
        `${apiProtocol}${council}${baseURL}${formSettings}${dotJSON}`,
        options
      )
      .pipe(
        map((res: any) => res.form_settings),
        tap((res: any) => {
          this.formSettings = res.form_settings;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  public getLocation(id: number) {
    const options = { headers };
    return this.http
      .get<Location>(
        `${apiProtocol}${council}${baseURL}${locations}/${id}${dotJSON}`,
        options
      )
      .pipe(
        map(
          (location: any) =>
            new Location(
              location.id,
              location.name,
              location.details,
              location.latitude,
              location.longitude,
              location.colour,
              location.content,
              location.category_ids
            )
        ),
        catchError(this.handleError.bind(this))
      );
  }

  public getSpecialEvent(id: number) {
    const options = { headers };
    return this.http
      .get<CalendarEvent>(
        `${apiProtocol}${council}${baseURL}${events}/${id}${dotJSON}`,
        options
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPushNotifications(next?: string) {
    const params = new HttpParams().set(
      "query[properties_id_eq]",
      this.propertyId
    );
    const options = {
      headers,
      observe: "response" as "response",
      params,
    };
    const url =
      next ||
      `${apiProtocol}${council}${baseURL}${pushNotifications}${dotJSON}`;
    return this.http
      .get<Alert[]>(url, options)
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPushNotification(id: string) {
    const options = { headers };
    return this.http
      .get<Alert>(
        `${apiProtocol}${council}${baseURL}${pushNotifications}/${id}${dotJSON}`,
        options
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  public async getVerification(): Promise<string> {
    if (this.verification) {
      return Promise.resolve(this.verification);
    } else {
      const verification = await this.storage.get("verification");
      this.verification = verification;
      return verification;
    }
  }

  public setVerification(verification: string): Promise<any> {
    this.verification = verification;
    return this.storage.set("verification", verification);
  }

  public async getRecipient(): Promise<Address> {
    if (this.recipient) {
      return Promise.resolve(this.recipient);
    } else {
      const recipient = await this.storage.get("recipient");
      this.recipient = recipient;
      return recipient;
    }
  }

  public setRecipient(recipient: Address): Promise<Address> {
    this.recipient = recipient;
    return this.storage.set("recipient", recipient);
  }

  public async getName(): Promise<string> {
    if (this.name) {
      return Promise.resolve(this.name);
    } else {
      const name = await this.storage.get("name");
      this.name = name;
      return name;
    }
  }

  public setName(name: string): Promise<string> {
    this.name = name;
    return this.storage.set("name", name);
  }

  public async getContacNo(): Promise<string> {
    if (this.contactNo) {
      return Promise.resolve(this.contactNo);
    } else {
      const contactNo = await this.storage.get("contactNo");
      this.contactNo = contactNo;
      return contactNo;
    }
  }

  public setContacNo(contactNo: string): Promise<string> {
    this.contactNo = contactNo;
    return this.storage.set("contactNo", contactNo);
  }

  public getNews(types?: NewsType[], next?: string) {
    let params = new HttpParams();
    types.forEach((type) => {
      params = params.append("news_type[]", encodeURIComponent(type));
    });
    const options = {
      headers,
      observe: "response" as "response",
      params,
    };
    const url = next || `${apiProtocol}${council}${baseURL}${news}${dotJSON}`;
    return this.http
      .get<News[]>(url, options)
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getNewsItem(id: number) {
    const options = { headers };
    return this.http
      .get<News>(
        `${apiProtocol}${council}${baseURL}${news}/${id}${dotJSON}`,
        options
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getLinkHeaders(httpHeaders: HttpHeaders) {
    if (httpHeaders.get("link")) {
      return httpHeaders
        .get("link")
        .split(",")
        .reduce((acc, link) => {
          const match = link.match(/<(.*)>; rel="(\w*)"/);
          const url = match[1];
          const rel = match[2];
          acc[rel] = url;
          return acc as any;
        }, {});
    } else {
      return null;
    }
  }

  private sendPushToken(pushToken: string) {
    const body = JSON.stringify({
      property: {
        push_token: pushToken,
      },
      old_push_token: this.oldPushToken,
    });
    const options = { headers };
    return this.http
      .patch<Property>(
        `${apiProtocol}${council}${baseURL}${properties}/${this.propertyId}${dotJSON}`,
        body,
        options
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(response: HttpErrorResponse) {
    if (response.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      console.error("An error occurred:", response.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${response.status}, ` +
          `body was: ${JSON.stringify(response.error)}`
      );
      if (response.error.status !== 404) {
        this.toastCtrl
          .create({
            message: response.error.error || errorMsg,
            duration: 5000,
            buttons: [
              {
                text: "Close",
                role: "cancel",
              },
            ],
          })
          .then((toast) => toast.present());
      }
    }
    return throwError(() => response.error);
  }
}
